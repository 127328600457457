@import '../../assets/global';

.shapeGroup{width:100%;}
.shapeGroup.rev{@include rotate(180deg);}
.path.color1{fill:$color1;}
.path.color2{fill:$color2;}
.path.tealLt{fill:$tealLt2;}
.path.lav{fill:$lav;}
.path.lavLt{fill:$lavLt;}
.path.lavLt2{fill:$lavLt2;}
.path.white{fill:$white;}
.path.black{fill:$black;}
.path.bg{fill:$bg;}
.path.gold{fill:$gold;}
.path.burg{fill:$burg}

.p1.inh, .p3.inh{fill:$lavLt;}
.p2.inh{fill:$lav;}