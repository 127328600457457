@import '../../assets/global.scss';

.About{position:relative;z-index:0;
  @media(min-width:2499px){.swoopBanner{width:100%;}}
  
  .intro{padding-top:100px;padding-bottom:20px;background-color:$color1;text-align:center;position:relative;z-index:2;
    &::before{@include before();width:80%;left:10%;height:1px;background-color:rgba($white,.2);}
    p, h1, h2, h3{color:$white;}
    p, h2{font-weight: $normal;}
    .h1{font-size:65px;font-weight:$dark;margin-bottom:30px;line-height:1.3em;}
    .desc{font-size:20px;line-height:1.5em;}
    .btnRow{@include sec-default();margin-top:15px;}
    button{font-size:17px;}
    

    @include bp(tw){padding-top:70px;padding-bottom:10px;
      &::before{width:90%;left:5%;}
      .h1{font-size:60px;}
    }
    @media (max-width:1100px){.sectionWrap{max-width:850px;}}
    @include bp(nn){background-color:$bg2;padding:15px 0 0;
      &::before{display:none;}
      .sectionWrap{max-width:740px;}
      p, h1, h2, h3{color:$black;}
      .h1{font-size:45px;margin-bottom:20px;}
      .desc{font-size:18px;}
      .btnRow{margin-top:0px;}
      button{color:$white;font-size:16px;
        &::before{background-color:$color1;}
        &::after{background-color:$white;}
        &:hover{color:$white !important;}
      }
    }
    @include bp(og){
      .h1{font-size:35px;margin-bottom:15px;}
      .desc{font-size:17px;}
      button{font-size:15px;}
    }
    @include bp(oc){
      .h1{font-size:27px;margin-bottom:10px;}
      .desc{font-size:16px;}
      button{font-size:15px;}
    }
  }

  .introWave{max-height:100px;margin-top:-20px;background-color:$lavLt2;
    svg{height:280px;}
    @include bp(tw){svg{height:240px;}}
    @include bp(nn){margin-top:-15px;background-color:$white;
      svg{height:200px;}
      path{fill:$bg2;}
    }
    @include bp(og){margin-top:-25px; svg{height:180px;}}
    @include bp(oc){margin-top:-10px; svg{height:140px;}}
  }
  .pageNav{padding-top:110px;background-color:$lavLt2;text-align:center;
    .optsFlex{display:flex;justify-content:space-between;padding-left:4%;padding-right:4%;}
    button{font-size:19px;font-weight:$bold;padding:0;}
    button::before, button::after{background-color:$color1;}
    .book button{color:$color2;font-weight:700;}
    // .moLabel{display:none;}
    // .moBtn{display:none;}

    @media (min-width:1601px){.optsFlex{justify-content:space-evenly;padding-left:3%;padding-right:3%;}}
    @include bp(su){button{font-size:20px;}}

    @include bp(tx){.optsFlex{padding-left:3%;padding-right:3%;}}
    @include bp(tw){padding-top:75px;
      button{font-size:17px;}
    }
    @include bp(nn){display:none;}
    // @include bp(nn){padding-top:60px;
    //   .optsFlex{flex-wrap:wrap;justify-content:space-around;
    //     max-width:650px;float:none;margin:auto;grid-row-gap:20px;
    //   }
    //   .fwBtn{display:none;}
    //   .moBtn{display:block;}
    // }
    // @include bp(og){padding-top:25px;}
    // @media (max-width:600px){
    //   .optsFlex{justify-content:space-evenly;grid-column-gap:20px;}
    //   button{font-size:16px;}
    // }
  }
  .outroWave{max-height:100px;
    svg{height:220px;}
    @include bp(tw){margin-top:-10px; 
      svg{height:200px;}
    }
    @include bp(nn){display:none;}
  }

  .faq{@include sec-default();padding-bottom:70px;
    .flexRow{align-items:start;justify-content:space-between;grid-row-gap:30px;}
    .summ{width:35%;}
    .info{width:60%;}
    
    h3{font-size:45px;color:$color1;font-weight:$dark;position:relative;z-index:0;
      &::before{@include before();
        width:220px;height:220px;top:-50px;left:-55px;
        background-size:90%;@include rotate(10deg);
        background-image:url('../../assets/images/dotsL.png');opacity:.15;
        background-position:top left;background-repeat:no-repeat;
      }
    }
    h4{font-size:18px;text-transform:uppercase;letter-spacing:3px;font-weight:$semi;margin-bottom:0;color:$color2;}
    p, li{font-weight:$normal;}
    li{margin-bottom:8px;}
    .cat{margin-bottom:7px;color:$color2;font-weight:$dark;}
    .tag{font-size:1.05em;font-weight:bold;color:$sig;}
    .mobBtn{display:none;}

    &.std{
      .info{margin-top:80px;}
      h3{padding-bottom:80px;margin-bottom:30px;
        &::after{@include after();width:35%;height:70px;left:0;bottom:-10px;border-right:1px solid rgba($color1, .3);}
      }
    }

    &.bg2{background-color:$bg2;
      h3::before{background-image:url('../../assets/images/dotsT.png');opacity:.1;}
    }

    &.method{padding-top:90px;}
    &.how li{line-height:1.6em;}
    &.us{
      .summ{width:40%;}
      .info{width:55%;}
    }

    @media (min-width:990px){.summ.sticky{position:sticky;top:80px;}}
    @include bp(dt){&.method h3{font-size:50px;}}
    @include bp(su){
      h3::before{@include rotate(0);left:-60px;top:-55px;}
    }

    @include bp(tx){h3::before{width:210px;height:210px;left:-45px;}}
    @include bp(tw){padding-bottom:50px;
      h3{font-size:39px;}
      h3::before{width:185px;height:185px;left:-35px;}
      h4{font-size:17px;}
      li{line-height:1.4em;}
      &.std{
        .info{margin-top:60px;}
        h3{padding-bottom:60px;margin-bottom:20px;
          &::after{height:50px;bottom:-5px;}
        }
      }
      &.method{padding-top:70px;}
    }
    @include bp(nn){padding-bottom:20px;
      .faqWrap{max-width:700px;}
      .flexRow{grid-row-gap:15px;}
      .summ, .info{width:100%;}
      .faqWrap h3, .faqWrap h4{display:none;}
      .mobBtn{display:block;}
      .fwBtn{display:none;}
    }
    @include bp(og){
      .info p, li{font-size:17px;}
    }
  }

  .faqAccords{display:none;}
  p.disclaimer, .disclaimer{font-size:15px;font-style:italic;}
  @include bp(nn){
    .fwFaqs{display:none;}
    .faqAccords{padding-top:20px;padding-bottom:10px;display:block;position:relative;z-index:4;
      .accGroup{margin-top:0;padding:30px 5%;}
      .groupHowSm{display:none;}
      h3{margin-bottom:0;color:$color1;position:relative;z-index:0;font-weight:700;}

      .accContent{margin-top:15px;padding-top:20px;border-top:1px solid rgba($black, .1);
        p:last-child{margin-bottom:0;}
      }
      .summ, .info{position:relative;z-index:0;}
      .summ::before, .info::after{@include abs();width:130px;height:100%;opacity:.15;z-index:-2;
        background-size:90%;background-image:url('../../assets/images/dotsL.png');background-repeat:no-repeat;
      }
      .summ::before{top:-30px;left:-50px;background-position:top left;}
      .info::after{bottom:-10px;right:-25px;background-position:bottom right;}

      .groupWhy, .groupAlt{background-color:$bg2;
        .summ::before, .info::after{background-image:url('../../assets/images/dotsT.png');opacity:.1;}
      }
    }
    @media (max-width:840px){
      .faqAccords{
        .groupHow{display:none;}
        .groupHowSm{display:block;}
      }
    }
    @include bp(og){
      .faqAccords{padding-top:10px;
        h3{font-size:24px;}
      }
    }
    @include bp(oc){
      .faqAccords{padding-top:0px;
        .accGroup{padding:20px 15px;}
        // .faqWrap{width:94%;}
        h3{font-size:22px;}
        .summ::before{width:110px;top:-20px;left:-35px;}
      }
    }
    @include bp(xs){.faqAccords h3{font-size:20px;}}
  }
}