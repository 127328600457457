@import '../../assets/global.scss';

.headerSec{@include sec-default();min-height:200px;padding-top:20px;position:relative;z-index:1;
  .shapeWrap{position:absolute;z-index:-1;left:0;top:-50px;
    svg{height:250px;}
  }
  .sectionWrap{width:95%;}  
  .mobNav{display:none;
    svg{color:$white;}
  }
  .navRow{display:flex;align-items:center;justify-content:space-between;}
  .nrItem{display:inline-flex;}
  .navWrap, .auxWrap{width:35%;}
  .logoItem{width:30%;}
  .logo{max-width:200px;@include center-block();}
  .logoP{display:none;}
  .navWrap{justify-content:flex-end;}
  ul{padding:0;margin-bottom:0;}
  .navItem{list-style-type:none;display:inline-block;margin:0 15px;}
  a{color:$white;font-weight:$normal;}
  a:hover{text-decoration:none;}
  .ibk button{color:$color1;
    &::before{background-color:$white;}
    &:hover{color:rgba($color1,.8) !important;opacity:.8;}
  }

  &.vlight{
    .logoW{display:none;}
    .logoP{display:block;}
    &, a, .icontact button{color:$color1;}
    .shapeWrap path{fill:$white;}
    .ibk button{background-color:$color1;color:$white;
      &::before{background-color:$color1;}
    }
    .mobNav svg{color:$color1;}
  }
  
  @include bp(tw){min-height:180px;
    .sectionWrap{width:100%;}
    .shapeWrap{top:-25px;
      svg{height:200px;}
    }
    a{font-weight:400;font-size:17px;}
  }
  @media (max-width:1070px){
    .shapeWrap svg{height:185px;}
    // .auxWrap ul{display:flex;align-items:center;flex-wrap:wrap;}
    .navWrap, .auxWrap{width:39%;}
    .navWrap{padding-right:10px;} 
    .auxWrap{padding-left:10px;}
    .logoItem{width:20%;}
    .logo{max-width:180px;}
  }

  @media (min-width:990px){
    &.nomin{min-height:unset;background-color:$color1;padding-bottom:20px;
      .shapeWrap{display:none;}
    }
  }
  @include bp(nn){
    .navWrap, .auxWrap{width:37.5%;}
    .logoItem{width:25%;}
    .navWrap{padding-right:30px;}
    .auxWrap{padding-left:30px;}
    .iemail{margin-bottom:7px;}
    a{font-size:16px;}

    &.nomin{background:$bg2;}
  }

  @include bp(og){min-height:125px;padding-top:15px;
    &::before{@include before();height:60px;top:-1px;background-color:$color1;}
    .shapeWrap{top:unset;bottom:5px;
      svg{height:95px;}
    }
    .sectionWrap{width:95%;max-width:550px;}
    .navRow{flex-direction:row-reverse;}
    .navWrap, .iemail{display:none;}
    .auxWrap, .mobNav{width:25%;}
    .logoItem{width:40%;}
    .logo{max-width:165px;}
    .auxWrap{padding-left:0;
      .navItem{margin:0;}
    }
    .mobNav{display:inline-flex;justify-content:center;
      .menuBtn{padding:0;}
    }
  }

  @include bp(fi){min-height:110px;
    .shapeWrap svg{height:60px;}
    .ibk button{font-size:14px;padding-left:12px;padding-right:12px;}
    .logo{max-width:140px;}
  }
}

.SideNavList{padding:20px 30px 0;width:100%;max-width:300px;height:100%;background:$color1;
  .closeIcon{position:absolute;right:10px;top:10px;cursor:pointer;font-weight:$bold;
    width:30px;height:30px;color:$white;
    svg{width:23px;height:23px;margin-bottom:2px;}
  }
  ul{padding-left:0;}
  li, .drEmail{@include sec-default();line-height:1em;margin-bottom:25px;}
  a{font-weight:400;text-transform:none;font-size:17px;line-height:1em;color:$white;}
  a:hover{text-decoration:none;}
  .mobOnly{display:block;}
  .fwOnly{display:none;}

  @include bp(sm){padding-left:20px;padding-right:20px;}
}
