@import './assets/global.scss';

*, *::before, *::after {box-sizing:border-box;}
.mainWrap{min-height:80vh;}

// .home header{display:none;}
.attnBanner{padding:15px 0 20px;text-align:center;position:relative;z-index:999;
  &, p, h4, h3{margin-bottom:0;}
  h3{font-size:21px;}
  p{font-size:16px;font-weight:$normal;}
  b{font-weight:$bold;}
  br{display:none;}
  // background-color:$red;&, p, h4, h3{color:$white;}
  background-color:$tealLt;&, p, h4, h3{color:$red;}

  @media (max-width:1115px){
    .sectionWrap{width:95%;max-width:740px;}
    // .sectionWrap{width:95%;max-width:100%;}
  }
  @include bp(nn){
    h3{font-size:18px;}
    p{font-size:16px;}
  }
  @include bp(fi){
    h3{font-size:17px;}
    p{font-size:15px;}
  }
}