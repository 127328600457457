@import '../../assets/global.scss';

.Contact{
  .intro{padding:50px 0 30px;text-align:center;
    h1{font-size:40px;margin-bottom:30px;font-weight:400;
      b{font-weight:$dark;}
    }
    p{font-size:20px;font-weight:$light;}

    @include bp(tw){padding-top:40px;
      .iwrap{max-width:900px;}
      h1{font-size:37px;}
    }
    @include bp(nn){padding:10px 0 15px;
      .iwrap{max-width:750px;}
      h1{font-size:32px;margin-bottom:20px;}
      p{font-size:18px;}
    }
    @include bp(og){padding:30px 0 20px;
      h1{font-size:28px;margin-bottom:15px;}
      p{font-size:17px;}
    }
    @include bp(oc){padding:20px 0 10px;
      h1{font-size:25px;}
    }
  }

  .contactInfo{
    .flexRow{padding:45px 0;position:relative;z-index:0;
      &::before, &::after{@include abs();width:120%;left:-10%;height:1px;background-color:rgba($black, .1);}
      &::before{top:0;}
      &::after{bottom:0;}
    }

    p{margin-bottom:0;}
    .email a{color:$black;
      &:hover{opacity:.7;}
    }
    @include bp(tw){.flexRow::before, .flexRow::after{width:105%;left:-2.5%;}}
    @include bp(nn){
      .cat{letter-spacing:1px;}
      p{font-size:17px;}
    }
    @include bp(og){background-color:$bg2;
      .ciWrap{max-width:550px;}
      .flexRow{padding:20px 0 25px; grid-row-gap:15px;
        &::before, &::after{display:none;}
      }
      .ibk{width:100%;}
    }
    @include bp(oc){
      .ciWrap{max-width:550px;}
      .flexRow{padding-top:15px; grid-row-gap:15px;
        flex-direction:column;align-items:center;text-align: center;
      }
      .ibk{margin-top:5px;}
    }
  }

  .formBlock{padding:60px 0 50px;
    .formGroup{margin-bottom:20px;}
    .halfSec{width:48%;display:inline-block;}
    .halfSec+.halfSec{float:right;}
    label, input, textarea{font-size:17px;width:100%;display:block;}
    label{margin-bottom:3px;}
    input, textarea{padding:7px 5px 5px 7px;background-color:rgba($bg2,.8);border:none;border-bottom:2px solid rgba($black,.1);font-family:$body;
      &:focus-visible {outline:1px solid rgba($color1, .2);background-color:darken($bg2, 1%);}
    }
    input{min-height:35px;}
    textarea{min-height:120px;}

    @include bp(og){padding:40px 0 20px;
      label, input, textarea{font-size:16px;}
    }
    @include bp(oc){padding:20px 0 10px;
      .formWrap{max-width:450px;}
      .halfSec{width:100%;}
    }
  }
}