@import '../../assets/global.scss';

.footerSec{@include sec-default();position:relative;text-align:center;overflow:hidden;
  .shapeWrap{min-height:90px;position:relative;z-index:-1;
    svg{position:absolute;left:0;top:0;}
    // svg{height:200px;}
  }
  .footer{@include sec-default();padding:0px 0 20px;background-color:$color1;}

  .menuWrap{display:flex;justify-content:center;align-items:center; border-bottom:1px solid rgba($white,.3);margin-bottom:20px;padding-bottom:25px;}
  ul{display:flex;list-style-type:none;justify-content:space-between;grid-column-gap:30px;align-items:center;margin-bottom:0;}
  a{color:$white;}

  .disclaimer{font-size:15px;color:$white;font-style:italic;margin-bottom:0;}

  @media (min-width:1500px){
    .shapeWrap svg{height:250px;}
    .footer{padding-top:0;}
  }
  @include bp(tw){.shapeWrap{min-height:75px;}}
  @include bp(nn){padding-top:10px;
    .shapeWrap{min-height:55px;
      svg{top:-10px;}
    }
    .footer{padding-bottom:10px;}
    a{font-size:17px;}
    button, .disclaimer{font-size:14px;}
  }

  @include bp(oc){
    .shapeWrap{min-height:40px;
      svg{top:-5px;}
    }
    .menuWrap{flex-direction:column;grid-gap:15px;padding-bottom:15px;margin-bottom:15px;}
    ul{grid-column-gap:20px;}
    a{font-size:16px;}
  }
  @include bp(sm){
    .menuWrap{grid-row-gap:10px;}
    ul:first-child{flex-wrap:wrap;grid-row-gap:10px;}
    ul.aux{align-items:center;text-align:center;flex-direction:column-reverse;grid-row-gap:14px;}
  }
}