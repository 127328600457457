@import '../../assets/global.scss';

.Sessions{position:relative;z-index:0;
  &::before{@include before();height:120px;background-color:$bg2;top:-100px;}
  @media(min-width:2499px){.swoopBanner{width:100%;}}

  .bg2{background-color:$bg2;}
  .intro{padding:50px 0 40px;text-align:center;
    h1{font-size:30px;font-weight:$normal;line-height:1.3em;position:relative;z-index:0;
      span{font-weight:$bold;}    
      &::before{@include before();
        width:150px;height:155px;top:-35px;left:10px;
        background-size:90%;@include rotate(10deg);
        background-image:url('../../assets/images/dotsT.png');opacity:.13;
        background-position:top left;background-repeat:no-repeat;
      }
    }
    .h1{font-size:25px;line-height:45px;font-weight:$bold;margin-bottom:0;}
    .ukTextSlide{
      ul{min-height:55px;width:unset;}
      li{font-size:45px;font-weight:bold;background-color:$bg2;color:$color2;}
    }
    .vborder{height:40px;position:relative;z-index:0;
      &::before{@include abs();width:49.9%;height:95%;left:0;top:2.5%;border-right:2px solid rgba($black,.1);}
      &.one{margin:15px 0 10px;}
      &.two{margin:5px 0 15px;}
    }

    @include bp(tw){padding-top:40px;}
    @include bp(tb){padding-top:5px;}
    @include bp(nn){
      h1{font-size:25px;
        &::before{width:100px;height:125px;top:-20px;left:70px;}
      }
      .h1{font-size:21px;line-height:40px;}
      .ukTextSlide{
        ul{min-height:50px;}
        li{font-size:40px;}
      }
      .vborder{height:20px;
        &::before{height:100%;top:0;}
        &.one{margin:10px 0 5px;}
        &.two{margin:0px 0 10px;}
      }
    }
    @include bp(og){padding-bottom:20px;padding-top:10px;
      .h1Wrap{max-width:500px;}
      h1{font-size:22px;max-width:400px;@include center-block();
        &::before{width:70px;height:105px;left:-10px;top:-15px;}
        br{display:none;}
      }
      .h1{font-size:19px;line-height:35px;}
      .ukTextSlide{
        ul{min-height:45px;}
        li{font-size:32px;}
      }
      .vborder.one{margin:5px 0 0px;}
    }

    @media (max-width:400px){padding-bottom:15px;
      h1{font-size:21px;}
      .h1{font-size:18px;line-height:33px;}
      .ukTextSlide{
        ul{min-height:38px;}
        li{font-size:27px;}
      }
      .vborder{height:10px;}
    }
  }
  
  .flexDef{
    @media (max-width:900px){width:94%;}
    @include bp(og){width:90%;}
  }
  .flexRow{padding-bottom:50px;justify-content:space-evenly;align-items:center;
    .one{width:33%;}
    .two{width:65%;position:relative;z-index:0;
      &::before{@include after();width:140px;height:155px;bottom:-10px;right:-30px;
        background-size:90%;@include rotate(10deg);
        background-image:url('../../assets/images/dotsP.png');opacity:.1;
        background-position:top left;background-repeat:no-repeat;
      }
    }
    b{font-weight:bold;}
    li{font-size:19px;margin:10px 0;}
    li:first-child{margin-top:0;}
    .num{font-weight:bold;color:$color1;}

    @include bp(tb){padding-bottom:35px;li{font-size:18px;}}
    @include bp(nn){padding-bottom:20px;justify-content:space-between;
      .two::before{width:110px;height:125px;bottom:-20px;right:-20px;}
      li, p{font-size:17px;}
      li{line-height:1.3em;}
    }
    @media (max-width:900px){
      .two{width:63%;
        &::before{bottom:-15px;right:0px;}
      }
    }
    @include bp(og){padding-bottom:10px;text-align:center;flex-direction:column;
      .one, .two{width:100%;}
      .two::before{display:none;}
      ul{max-width:500px;@include center-block();padding-left:0;margin-bottom:10px;}
      li{font-size:16px;display:inline-block;margin:5px 7px;position:relative;z-index:0;
        &:first-child{margin-top:5px;}
        &:nth-child(even)::before{@include before();width:3px;left:-8px;height:3px;top:50%;margin-top:-1.5px;background-color:rgba($color1, .9);border-radius:50%;}
      }
    }
    @include bp(fi){
      li{width:100%;margin:0 0 5px;
        &::before{display:none;}
        &:first-child{margin-top:0px;}
      }
    }
  }

  .summary{padding-bottom:60px;
    .tbox{display:inline-block;padding:50px 50px 30px 55px;border-radius:$radius;background-color:$bg2;
      position:relative;z-index:0;
      &::before{@include before();
        width:140px;height:130px;top:15px;left:15px;
        background-size:105px;@include rotate(10deg);
        background-image:url('../../assets/images/dotsT.png');
        background-position:top left;background-repeat:no-repeat;opacity:.1;
      }
    }

    h3{color:$color1;}
    li{margin-bottom:12px;line-height:1.6em;}
    p.hd{font-weight:bold;margin-bottom:0;}
    .clearfixBtn{margin-bottom:35px;}
    .ucv .sig{color:$color2;letter-spacing:.5px;}
    .req{font-size:17px;font-style:italic;}

    .iwhy, .ihow{
      h3{font-size:26px;margin-bottom:5px;}
      h4{font-size:17px;margin-bottom:30px;font-weight:$normal;}
    }
    .iwhy{width:55%;float:left;margin-left:2%;}
    .ihow{width:40%;float:right;margin-top:70px;margin-right:-2%;}
    .ialt{width:62%;margin-left:-5%;margin-top:70px; 
      h3 span{font-size:20px;line-height:1.7em;font-weight:$normal;color:$black;}
      h4{font-size:18px;}
    }

    @media (max-width:1600px){
      .tbox{padding:35px 40px 20px 45px;}
      .inew{margin-left:-3%;}
      .ialt{margin-left:-3%;margin-top:50px;}
    }

    @include bp(tx){
      .sectionWrap{width:92%;}
      .tbox{padding:30px 35px 20px;}
      .iwhy{margin-left:0;}
      .ihow{width:44%;margin-right:-1%;}
      .ialt{width:58%;margin-top:30px;}
    }

    @include bp(tw){//padding-bottom:60px;
      .iwhy, .ihow{
        h3{font-size:24px;}
      }
      .ialt h3 span{font-size:19px;}
      .ialt{width:57%;}
    }

    @include bp(nn){padding:30px 0 40px;
      .sectionWrap{width:94%;}
      .tbox{padding:25px 25px 10px;
        p, li{font-size:17px;}
        ul{padding-left:10px;}
      }
      .iwhy, .ihow{
        h3{font-size:19px;}
        h4{font-size:16px;margin-bottom:20px;}
      }
      .iwhy{width:54%;}
      .ihow{margin-right:0;margin-top:0px;}
      .ialt{margin-left:0;margin-top:30px;padding-bottom:20px;
        h3{font-size:19px;}
        h3 span{font-size:16px;}
        h4{font-size:17px;}
      }
      .ialt{width:51%;}
    }

    @media (min-width:767px) and (max-width:780px){
      .ihow a+button{margin-left:5px;}
    }

    @include bp(og){padding:20px 0;
      .sectionWrap{width:92%;max-width:650px;}
      .iwhy, .ihow, .ialt{width:100%;margin:0 0 20px;}
      .tbox{padding:30px 30px 5px;
        .kpLink{font-size:14px;}
      }
      .ialt p{margin-bottom:12px;}
    }

    @include bp(fi){
      .sectionWrap{width:96%;}
      .tbox{padding:30px 15px 5px;}
    }
  }
}
