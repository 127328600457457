@import '../../assets/global.scss';

.Home{background-color:$bg2;position:relative;z-index:0;
  &::before{@include before();height:120px;background-color:$bg2;top:-100px;}

  .introAlt{padding-top:50px;margin-top:30px;position:relative;z-index:4;
    .wrap1{position:relative;z-index:0;}
    .wrap1::before{@include before();width:1px;left:30%; background-color:rgba($black, .1);}
    .i1{width:30%;max-width:300px;}
    .i2{width:65%;}
    img.rd{border-top-left-radius:60px;border-bottom-left-radius:60px;  margin-bottom:30px;}
    h1{font-size:30px;margin-bottom:20px;line-height:1.3em;font-weight:$dark;}
    h2{font-size:18px;font-weight:$light;}
    h3, .tag{font-size:20px;font-weight:bold;color:$color2;}
    .btnRow{margin-top:10px;}
    button, .kpLink{font-size:16px;}

    @include bp(su){margin-top:40px;}
    @media (min-width:1300px){
      .wrap1{width:95%;}
      h1{font-size:32px;}
    }
    @media (max-width:1299px){
      .i1, .i2{padding-right:10px;}
      .i2{width:66%;}
    }
    @include bp(tw){margin-top:0;
      .i1, .i2{padding-right:0;}
      .wrap1::before{display:none;}
      .i1{width:26%;}
      .i2{width:70%; padding-left:4%;border-left:1px solid rgba($black, .1);}
    }
    @include bp(nn){padding-top:10px;
      .wrap1{max-width:650px;}
      .wrap1::before{display:none;}
      .i1{display:none;}
      .i2{width:100%;padding:0;border:none;}
      h1{font-size:28px;}
      h2{font-size:17px;font-weight:$normal;margin-bottom:10px;}
      h2 b{font-weight:$semi;}
      h3{font-size:18px;}
      button, .kpLink{font-size:15px;}
    }
    @include bp(og){padding:20px 0 0;
      .wrap1{max-width:600px;}
      h1{font-size:25px;}
      h2{font-size:16px;}
      h3{font-size:18px;margin-bottom:10px;}
    }
    @include bp(sm){h1{font-size:25px;}}
  }

  .swoopBannerWrap{margin-top:-60px;position:relative;z-index:0;
    @media(min-width:2499px){.swoopBanner{width:100%;}}
    .topQuote{width:460px;position:absolute;right:20px;top:200px;
      p{font-size:33px;font-weight:800;line-height:1.4em;}
      span{position:relative;z-index:0;}
      span::after{@include abs();width:100%;height:13px;bottom:2px;left:0;background-color:$color1;opacity:.15;}
      .line1{float:left;}
      .line2{float:right;}
    }
    .logo{display:none;}

    @media (max-width:1500px){.topQuote{max-width:420px;right:10px;}}
    @include bp(tx){
      .topQuote{max-width:340px;right:15px;top:160px;
        p{font-size:29px;}
        span::after{height:11px;}
      }
    }
    @include bp(tw){margin-top:-30px;
      .topQuote{max-width:325px;right:15px;top:140px;
        p{font-size:27px;}
        span::after{height:10px;}
      }
    }
    @include bp(nn){margin-top:-70px;
      .topQuote{display:none;}
      .logo{display:block;position:absolute; width:50%;max-width:200px;top:100px;right:40px;}
    }
    @include bp(ft){.logo{right:25px;}}
    @include bp(og){margin-top:-50px;
      .logo{max-width:180px;top:90px;right:20px;z-index:2;}
    }
    @include bp(oc){margin-top:-20px;z-index:2;
      .logo{max-width:150px;top:75px;right:15px;}
    }
    @include bp(fi){margin-top:0px;
      .logo{max-width:120px;top:65px;}
    }
  }

  .summary{padding:80px 0 100px; background-color:$white;position:relative;z-index:0;
    .tbox{display:inline-block;padding:50px 50px 30px 55px;border-radius:$radius;background-color:$bg2;
      position:relative;z-index:0;
      &::before{@include before();
        width:140px;height:130px;top:15px;left:15px;
        background-size:105px;@include rotate(10deg);
        background-image:url('../../assets/images/dotsT.png');
        background-position:top left;background-repeat:no-repeat;opacity:.1;
      }
    }

    h3{color:$color1;}
    li{margin-bottom:12px;line-height:1.6em;}
    p.hd{font-weight:bold;margin-bottom:0;}
    .clearfixBtn{margin-bottom:35px;}
    .ucv .sig{color:$color2;letter-spacing:.5px;}
    .req{font-size:17px;font-style:italic;}

    .iwhy, .ihow{
      h3{font-size:26px;margin-bottom:5px;}
      h4{font-size:17px;margin-bottom:30px;font-weight:$normal;}
    }
    .iwhy{width:55%;float:left;margin-left:2%;}
    .ihow{width:40%;float:right;margin-top:100px;margin-right:-2%;
      margin-top:70px;
    }
    .inew, .ialt{width:51%;margin-left:-5%;margin-top:70px; 
      h3 span{font-size:20px;line-height:1.7em;font-weight:$normal;color:$black;}
    }
    .ialt{width:62%;
      h4{font-size:18px;}
    }

    .quote{width:44%;max-width:640px;float:right;margin-top:-100px;margin-right:2%;
      p{font-size:50px;font-weight:800;line-height:1.4em;}
      span{position:relative;z-index:0;}
      span::after{@include abs();width:100%;height:20px;bottom:2px;left:0;background-color:$color2;opacity:.5;}
      .line1{float:left;}
      .line2{float:right;}
    }

    // @include bp(dt){.ialt+.quote{display:none;}}
    @media (min-width:990px){.ialt+.quote{display:none;}}

    @media (max-width:1600px){
      .tbox{padding:35px 40px 20px 45px;}
      .inew{margin-left:-3%;}
      .ialt{margin-left:-3%;margin-top:50px;}
      .quote{margin-right:1%;  
        p{font-size:45px;}
        span::after{height:16px;bottom:4px;}
      }
    }
    @media (max-width:1450px){.quote{margin-top:-150px;}}

    @include bp(tx){
      .sectionWrap{width:92%;}
      .tbox{padding:30px 35px 20px;}
      .iwhy{margin-left:0;}
      .ihow{width:44%;margin-right:-1%;}
      .inew{width:53%;margin-top:50px;}
      .ialt{width:58%;margin-top:30px;}
      .quote{
        p{font-size:38px;}
        span::after{height:13px;}
      }
    }

    @include bp(tw){padding:60px 0 80px;
      .iwhy, .ihow{
        h3{font-size:24px;}
      }
      // .ihow{margin-top:60px;}
      .inew h3 span,
      .ialt h3 span{font-size:19px;}
      .ialt{width:57%;}//51%;
      .quote{margin-top:-180px;
        p{font-size:35px;}
        span::after{height:12px;}
      }
    }

    @include bp(tb){.quote{margin-top:-220px;}}

    @include bp(nn){padding-bottom:60px;
      .sectionWrap{width:94%;}
      .tbox{padding:25px 25px 10px;
        p, li{font-size:17px;}
        ul{padding-left:10px;}
      }
      .iwhy, .ihow{
        h3{font-size:19px;}
        h4{font-size:16px;margin-bottom:20px;}
      }
      .iwhy{width:54%;}
      .ihow{margin-right:0;margin-top:0px;}
      .inew, .ialt{margin-left:0;margin-top:30px;padding-bottom:20px;
        h3{font-size:19px;}
        h3 span{font-size:16px;}
        h4{font-size:17px;}
      }
      .ialt{width:51%;}
      .quote{width:42%;
        // margin-top:-210px;
        margin-top:-100px;
        p{font-size:27px;}
        span::after{height:8px;}
      }
    }

    // @media (max-width:900px){.quote{margin-top:-260px;}}
    @media (max-width:900px){.quote{margin-top:-160px;}}

    @media (min-width:767px) and (max-width:780px){
      .ihow a+button{margin-left:5px;}
    }

    @include bp(og){padding-top:30px;padding-bottom:50px;
      .sectionWrap{width:92%;max-width:650px;}
      .iwhy, .ihow, .inew, .ialt{width:100%;margin:0 0 20px;}
      .tbox{padding:30px 30px 5px;
        .kpLink{font-size:14px;}
      }
      .inew p, .ialt p{margin-bottom:12px;}
      .quote{width:100%;max-width:400px;@include center-block();margin-top:0px;}
    }

    @include bp(fi){
      .sectionWrap{width:96%;}
      .tbox{padding:30px 15px 5px;}
      .quote{max-width:300px;
        p{font-size:23px;}
        span::after{height:40%;bottom:-1px;opacity:.3;}
      }
    }
  }

  .hs3{padding:100px 0;//background-color:#f1f2f4;
    h3{font-size:30px;margin-bottom:80px;text-align:center;}
    .disc{font-size:16px;font-style:italic;}
  }
}