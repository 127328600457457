@import './variables.scss';
@import './mixins.scss';

html {font-size:18px;color:$black;font-family:$body;}
body{margin:0; font-weight:$normal;}
::selection{background:$color1;}

h1, h2, h3, h4, h5, p{margin-top:0;color:$black;font-family:inherit;}
h1, h2, h3, h4, h5, p, li{
  &.up, span.up{text-transform:uppercase;}
  &.cap, span.cap{text-transform:capitalize;}
  &.em, span.em{font-style:italic;}
  &.bold, span.bold{font-weight:$bold;}
  &.cat, span.cat{
    font-size:15px;letter-spacing:2px;
    font-weight:$dark;line-height:1.1em;
    font-family:$body;text-transform:uppercase;margin-bottom:0;
  }
  &.white, span.white, b.white{color:$white;}
  &.color1, span.color1, b.color1{color:$color1;}
  &.color2, span.color2, b.color2{color:$color2;}
  &.red, span.red, b.red{color:$red;}
  &.bmargin0{margin-bottom:0;}
  b.semi, .semi{font-weight:$semi;}
}
h3, h4{font-weight:700;}
h1{font-size:45px;margin-bottom:inherit;line-height:1.2em;}
h2{line-height:1.6em;font-weight:$light;}
p, h4, h5{margin-bottom:20px;}
p{line-height:1.6em;}

.accordion-title span, .accordion-title h3{display:inline-block;}
.accordion-title:hover{cursor:pointer;}

img{@include center-block();max-width:100%;}
ul{padding-left:20px;margin-top:0;}
ul.none{padding-left:0px;}
hr{border-color:rgba($black, .1);}

.inside, .insideXl, .insideAlt, .insidePw, .insideTw, .medDef, .med2Def, .med3Def, .med4Def, .insideNrw{@include center-block();width:90%;max-width:1800px;}
.insideXl{width:95%;}
.insideAlt{width:85%;}
.insidePw{max-width:1500px;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}

.pageStyles, .fullRow, .fullSec{@include sec-default();}
.flexRow{display:flex;flex-wrap:wrap;
  &.jbw{justify-content:space-between;}
  &.jc{justify-content:center;}
  &.ja{justify-content:space-around;}
  &.je{justify-content:space-evenly;}
  &.ac{align-items:center;}
  &.rev{flex-direction:row-reverse;}
  
  &.even2 .item{width:48%;}
  &.even3 .item{width:32%;}
}

.pageStyles{padding-top:50px;}
.centerBlock{@include center-block();}
.textCenter{text-align:center;}

.clearfix::after, .clearfixBtn::after {content:"";clear:both;display:table;}
.clearfixBtn{@include sec-default(); margin-top:10px;}

a{text-decoration:none !important;color:$color1;
  &:hover{opacity:.8;@include ease(.3s);}
}
button{background:inherit;border:inherit;border-radius:inherit;color:inherit;
  font-weight:inherit;font-family:inherit;font-size:inherit;letter-spacing:inherit;
  span.em{font-style:italic;}
  &:hover{cursor:pointer;color:inherit !important;}
}

.kpLink, .kpLinkSm{ 
  color:$white;text-align:center;
  font-size:15px;line-height:1em;letter-spacing:.3px;font-weight:$bold;
  padding:10px 25px 11px;box-shadow:0 10px 25px rgba($black,.13);
  position:relative;z-index:0;

  &, &::before, &::after{border-radius:25px;}
  &::after{@include before();background-color:$white;z-index:-2;}
  &::before{@include before();background-color:$color1;}
  &:hover{text-decoration:none;color:$white !important;opacity:1;
    &::before{opacity:.85;@include ease(.3s);}
  }
  &.full{@include sec-default();}
  &.up{text-transform:uppercase;}
  &.kpLinkSm{padding-left:20px;padding-right:20px;}
  &.rev{color:$color1;
    &::after{background-color:$color1;}
    &::before{background-color:$white;}
    &:hover{color:$color1 !important;}
  }
  // @include bp(tb){padding-left:20px;padding-right:20px;}
}
button.kpLink:not(.kpLinkSm){padding-top:12px;padding-bottom:13px;}
// button.kpLinkSm{padding-top:10px;padding-bottom:10.5px;}
.kpLink+.kpLink, button+.kpLink, .kpLink+button, button+button{margin-left:10px;}


.textLink{position:relative;z-index:0;
  &::before, &::after{@include abs();left:-2%;height:4px;bottom:0px;}
  &::before{width:104%;background-color:$color1;opacity:.2;}
  &::after{width:0;background-color:$color1;opacity:.6;transition: all .3s;}
  &:hover{opacity:1;color:currentColor;
    &::after{width:104%;}
  }
}

.stickyPageMenu.uk-sticky-fixed{padding:7px 0 12px;background-color:$lavLt;box-shadow:0px 5px 15px rgba($black, .1);}

.upBtn{position:fixed;z-index:999;
  width:30px;height:30px;right:25px;bottom:15%;padding:0;
  background-color:darken($color2, 10%);color:$white;text-align:center;border-radius:50%;
  display:flex;align-items:center;justify-content:center;box-shadow:0px 5px 15px rgba($black, .2);
  svg{width:28px;height:28px;margin-bottom:3px;}
  &:hover{opacity:.8;color:rgba($white,.8) !important;}
  @include bp(tw){right:15px;}
}